<script>
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      marketing_campaign_channel_events: [],
      marketing_channels: [],
      marketing_campaigns: [],
      MarketingCampaignChannels: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMarketingCampaignChannels: null,
      MarketingCampaignChannelsSearchMode: false,
      searchModel: "",
    };
  },
  methods: {
    getChannels() {
      this.http.get("marketing-channels", null, null, true).then((res) => {
        this.marketing_channels = res.data;
      });
    },
    getCampaigns() {
      this.http.get("marketing-campaigns", null, null, true).then((res) => {
        this.marketing_campaigns = res.data;
      });
    },
    addMarketingCampaignChannels() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "marketing_channel_id",
            type: "select",
            options: (() => {
              return this.marketing_channels?.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "marketing.campaign_channels_management.channel",
          },
          {
            model: "total_budget",
            type: "number",
            label: "marketing.campaign_channels_management.total_budget",
            required: true,
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {

              obj.marketing_campaign_id = this.$route.params?.id;
              console.log({ obj });
              this.http.post("marketing-campaign-channels", obj).then((res) => {
                if (res.status) {
                  this.popup.alert({ title: '', msg: 'popups.success' })
                  this.get(this.page);
                }
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editMarketingCampaignChannels(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "marketing_channel_id",
            type: "select",
            options: (() => {
              return this.marketing_channels.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "marketing.campaign_channels_management.channel",
            value: app.marketing_channel_id,
          },
          {
            model: "total_budget",
            type: "number",
            label: "total_budget",
            value: app.total_budget,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http
                .put("marketing-campaign-channels", app.id, obj)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.MarketingCampaignChannelsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("marketing-campaign-channels/by-campaign-id/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          marketing_campaign_id: this.$route.params?.id,
        }, null, true)
        .then((res) => {
          this.MarketingCampaignChannels = res.data;
          console.log("######## files", this.MarketingCampaignChannels);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.MarketingCampaignChannelsSearchMode = false;
      this.get(this.page);
    },

    getMarketingCampaignChannels() {
      this.http.get("marketing-campaign-channels", null, null, true).then((res) => {
        this.MarketingCampaignChannels = res.data;
      });
    },
    deleteMarketingCampaignChannels(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("marketing-campaign-channels", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("marketing-campaign-channels/by-campaign-id/pagination", {
          limit: this.limit,
          page: page,
          marketing_campaign_id: this.$route.params?.id,
        }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.MarketingCampaignChannels = res.data;

        });
    },
  },
  created() {
    this.getChannels();
    this.getCampaigns();
    this.get(1);
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div>
        <div class="row align-items-center">
          <div class="col-4"></div>

          <div class="col-8">
            <div class="search-box chat-search-box d-flex align-items-center"
              style="width: 250px; margin-inline-start: auto">
              <div class="position-relative mx-3 d-flex align-items-center">
                <input @keyup="search()" type="text" class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')"
                  v-model="searchModel" style="background-color: #2a3042 !important" />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <button @click="cancelappsearchMode()" v-if="searchModel" class="btn-close text-light"
                  style="position: absolute; top: 12px; left: 250px"></button>
              </div>
              <button type="button" class="btn btn-light float-end" @click="addMarketingCampaignChannels">
                <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
              </button>
            </div>
          </div>
        </div>
        <!-- end row -->

        <hr class="mb-4" />

        <div class="row">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
            <thead>
              <tr class="text-light text-center" style="background-color: #2a3042 !important">
                <th scope="col">{{ $t("marketing.id") }}</th>
                <th scope="col">
                  {{ $t("marketing.campaign_channels_management.channel") }}
                </th>
                <th scope="col">
                  {{
                    $t("marketing.campaign_channels_management.total_budget")
                  }}
                </th>
                <th scope="col">{{ $t("marketing.created") }}</th>
                <th scope="col">{{ $t("marketing.updated") }}</th>
                <th scope="col">
                  {{ $t("marketing.operations") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, i) in MarketingCampaignChannels" :key="app" class="text-center">
                <td>{{ i + 1 }}</td>
                <td>{{ app?.marketing_channel?.name }}</td>
                <td>{{ app?.total_budget }}</td>
                <td>{{ app?.created?.split("T")[0] }}</td>
                <td>{{ app?.created?.split("T")[0] }}</td>

                <td class="d-flex justify-content-end">
                  <router-link
                    :to="{ name: 'marketingCampaignChannels', params: { id: $route.params?.id, channelId: app?.id } }">
                    <button class="btn btn-info mx-1" role="button" data-toggle="tooltip" data-placement="top" :title="app?.marketing_channel?.name +
                      ' ' +
                      $t('popups.details')
                      ">
                      <i class="bx bxs-detail"></i>
                    </button>
                  </router-link>
                  <a class="btn btn-primary mx-1" @click="editMarketingCampaignChannels(app)" href="javascript: void(0);"
                    role="button">{{ $t("popups.edit") }}</a>
                  <a class="btn btn-danger" href="javascript: void(0);" role="button"
                    @click="deleteMarketingCampaignChannels(app)">{{ $t("popups.delete") }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- marketing-survey-team-members -->
