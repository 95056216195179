<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      data: {},
      marketingCampaignAreas: [],
      archive_files_types: [],
      marketingAreas: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchmarketingCampaignAreas: null,
      marketingCampaignAreasSearchMode: false,
      searchModel: "",
    };
  },
  methods: {
    addmarketingCampaignAreas() {
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "marketing_area_id",
            type: "select",
            options: (() => {
              return this.marketingAreas.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "popups.add_area",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.marketing_campaigning_id = this.$route.params?.id;
              this.http.post("marketing-campaign-areas", obj, null, true).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.marketingCampaignAreasSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("marketing-campaign-areas/by-campaign-id/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
          marketing_campaigning_id: this.$route.params?.id,
        }, null, true)
        .then((res) => {
          this.marketingCampaignAreas = res.data;
          console.log("######## files", this.marketingCampaignAreas);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.marketingCampaignAreasSearchMode = false;
      this.get(this.page);
    },
    getMarketingAreas() {
      this.http.get("marketing-areas", null, null, true).then((res) => {
        this.marketingAreas = res.data;
      });
    },
    getmarketingCampaignAreas() {
      this.http
        .post("marketing-campaign-areas/by-campaign-id", {
          marketing_survey_id: this.$route.params?.id,
        }, null, true)
        .then((res) => {
          this.marketingCampaignAreas = res.data;
        });
    },
    deletemarketingCampaignAreas(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("marketing-campaign-areas", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("marketing-campaign-areas/by-campaign-id/pagination", {
          limit: this.limit,
          page: page,
          marketing_campaigning_id: this.$route.params?.id,
        }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          console.log({ returnedData: res.data });
          this.marketingCampaignAreas = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getMarketingAreas();
  },
};
</script>

<template>
  <div class="row align-items-center">
    <div class="col-4"></div>

    <div class="col-8">
      <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px; margin-inline-start: auto">
        <div class="position-relative mx-3 d-flex align-items-center">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button @click="cancelappsearchMode()" v-if="searchModel" class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"></button>
        </div>
        <button type="button" class="btn btn-light float-end" @click="addmarketingCampaignAreas">
          <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
        </button>
      </div>
    </div>
  </div>
  <!-- end row -->

  <hr class="mb-4" />

  <div class="row">
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.id") }}
          </th>
          <th scope="col">
            {{ $t("archiving_categories.archiving_categories_table.name") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.created") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.updated") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.operations") }}
          </th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, i) in marketingCampaignAreas" :key="app" class="text-center">
          <td>{{ i + 1 }}</td>
          <td>{{ app?.marketing_area?.name }}</td>
          <td>{{ app?.created.split("T")[0] }}</td>
          <td>{{ app?.updated.split("T")[0] }}</td>
          <td class="d-flex justify-content-end">
            <a class="btn btn-danger" href="javascript: void(0);" role="button"
              @click="deletemarketingCampaignAreas(app)">{{ $t("popups.delete") }}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div>
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
