<script>
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
import MarketingAreas from "./components/MarketingAreas.vue";
import MarketingChannels from "./components/MarketingChannels.vue";
import MarketingMember from "./components/MarketingMember.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      tot_pages: 0,
      campaing: {},
    };
  },
  methods: {
    getCampaign() {
      this.http
        .get("marketing-campaigns/" + this.$route.params?.id, null, null, true)
        .then((res) => {
          this.campaing = res.data;
          console.log({ campaing: this.campaing });
        });
    },
  },
  created() {
    this.getCampaign();
  },
  components: {
    // PageHeader,
    MarketingMember,
    MarketingAreas,
    MarketingChannels

  },
};
</script>

<template>
  <!-- <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.marketing.subItems.marketing_survey')"
    /> -->
  <div class="d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center">
      <router-link :to="`/dashboard/marketing_campaign_management`">
        <h4 class="m-0">
          {{
        $t(
          "menu.menuitems.marketing.subItems.marketing_campaign_management"
        )
      }}
        </h4>
      </router-link>
      <div class="mx-2">\</div>
      <div>{{ campaing?.name }}</div>
    </div>
  </div>
  <div class="row">
    <b-tabs content-class="p-4" class="pt-2" nav-wrapper-class="nav-item"
      nav-class="justify-content-start nav-tabs-custom">
      <!-- marketing channels -->
      <b-tab :title="$t('marketing.tabs.channels')" active>
        <marketing-channels></marketing-channels>
      </b-tab>
      <!-- marketing areas -->
      <b-tab :title="$t('marketing.tabs.areas')">
        <marketing-areas></marketing-areas>
      </b-tab>
      <!-- marketing team Member -->
      <b-tab :title="$t('marketing.tabs.member')">
        <marketing-member></marketing-member>
      </b-tab>
    </b-tabs>
  </div>
  <!-- end  table -->

  <!--   Apps  Pagination     -->
  <ul v-if="tot_pages >= 20" class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == total_pages }">
      <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>
